import { RootState } from "../rootReducer";

export const documentsSelector = (state: RootState) => state.document.data;

export const isLoadingDocumentsSelector = (state: RootState) =>
  state.document.loading;

export const neverLoadedDocumentsSelector = (state: RootState) =>
  state.document.neverLoaded;

export const documentsByCommitmentIdSelector = (
  state: RootState,
  commitmentId: string
) => state.document.data.filter((doc) => doc.commitmentId === commitmentId);
