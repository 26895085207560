import React, { useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvestorAPI } from "@state/investor/investor.actions";
import { fetchDealsAPI, fetchExpiredDealsAPI } from "@state/deal/deal.actions";
import { fetchPortfolioAPI } from "@state/portfolio/portfolio.actions";
import { getUserInfoAsync } from "@state/auth/auth.actions";
import {
  neverLoadedDealSelector,
  neverLoadedExpiredDealSelector,
} from "@state/deal/deal.selectors";
import { neverLoadedInvestorSelector } from "@state/investor/investor.selectors";
import { neverLoadedPortfolioSelector } from "@state/portfolio/portfolio.selectors";
import {
  authSelector,
  isAuthenticatedSelector,
  neverLoadedAuthSelector,
} from "@state/auth/auth.selectors";
import isSafari from "@helpers/isSafari";
import { neverLoadedEntitiesSelector } from "@state/entity/entity.selectors";
import { fetchEntitiesAPI } from "@state/entity/entity.actions";
import { neverLoadedNotificationSelector } from "@state/notification/notification.selectors";
import { fetchNotificationsThunk } from "@state/notification/notification.actions";
import { neverLoadedRemoteConfigurationSelector } from "@state/remoteConfiguration/remoteConfiguration.selectors";
import { fetchRemoteConfigThunk } from "@state/remoteConfiguration/remoteConfiguration.actions";
import { neverLoadedBankAccountsSelector } from "@state/bankAccount/bankAccount.selectors";
import { fetchBankAccountsAPI } from "@state/bankAccount/bankAccount.actions";
import { fetchDocumentsAPI } from "@state/document/document.actions";
import { neverLoadedDocumentsSelector } from "@state/document/document.selectors";

const PreFetchData: FC = ({ children }) => {
  const dispatch = useDispatch();
  const neverLoadedAuth = useSelector(neverLoadedAuthSelector);
  const neverLoadedDeals = useSelector(neverLoadedDealSelector);
  const neverLoadedExpiredDeals = useSelector(neverLoadedExpiredDealSelector);
  const neverLoadedInvestor = useSelector(neverLoadedInvestorSelector);
  const neverLoadedPortfolio = useSelector(neverLoadedPortfolioSelector);
  const neverLoadedEntities = useSelector(neverLoadedEntitiesSelector);
  const neverLoadedNotification = useSelector(neverLoadedNotificationSelector);
  const neverLoadedRemoteConfig = useSelector(
    neverLoadedRemoteConfigurationSelector
  );
  const neverLoadedBankAccounts = useSelector(neverLoadedBankAccountsSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const authUser = useSelector(authSelector);
  const neverLoadedDocuments = useSelector(neverLoadedDocumentsSelector);

  const fetch = async () => {
    const safari = isSafari();

    // Prevents make many request that will return 401 if the user log out from the app
    if (!isAuthenticated) {
      return;
    }

    if (neverLoadedRemoteConfig) {
      await dispatch(fetchRemoteConfigThunk());
    }

    if (!authUser.id) {
      await dispatch(getUserInfoAsync());
    }

    if (safari && neverLoadedAuth) {
      await dispatch(getUserInfoAsync());
    }

    if (neverLoadedInvestor) {
      await dispatch(getInvestorAPI());
    }

    if (neverLoadedDeals) {
      await dispatch(fetchDealsAPI());
    }

    if (neverLoadedExpiredDeals) {
      await dispatch(fetchExpiredDealsAPI());
    }

    if (neverLoadedNotification) {
      await dispatch(fetchNotificationsThunk());
    }

    if (neverLoadedPortfolio) {
      await dispatch(fetchPortfolioAPI());
    }

    if (neverLoadedDocuments) {
      await dispatch(fetchDocumentsAPI());
    }

    if (neverLoadedEntities) {
      await dispatch(fetchEntitiesAPI());
    }

    if (neverLoadedBankAccounts) {
      await dispatch(fetchBankAccountsAPI());
    }
  };

  const loadData = async () => {
    await fetch();
  };

  useEffect(() => {
    loadData();
  }, [isAuthenticated]);

  return <>{children}</>;
};

export default PreFetchData;
