import React, { FC } from "react";
import { Router, useLocation, Redirect } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "../components/PrivateRoute";
import ForgotPassword from "../components/forgotPassword/ForgotPassword";
import LoginContainer from "../components/Login/components/Login.container";
import Layout from "../layouts/layout";
import ResetPassword from "../components/resetPassword/ResetPassword";
import SEO from "../components/seo/Seo";
import SignupForm from "../components/signupForm";
import SignupApple from "../components/Auth/SignupApple";
import VerifyEmail from "../components/verifyEmail/VerifyEmail.container";
import NotFound from "@components/notFound/NotFound";
import SecurityLayer from "@ifgengineering/client-security-layer";
const Onboarding = React.lazy(() => import("../components/onboarding"));
const Identity = React.lazy(() => import("../components/Identity"));
const UserProfile = React.lazy(() => import("../components/userProfile"));
const DealsPage = React.lazy(() => import("../components/deals"));
const MultipleInvestmentsDealPage = React.lazy(
  () => import("../components/deals/dealDetail/MultipleInvestments")
);
const BankDetailsPage = React.lazy(() => import("../components/bankDetails"));
const Webinar = React.lazy(() => import("../components/webinar/Webinar"));
const InvestorCall = React.lazy(
  () =>
    import("../components/deals/dealDetail/MultipleInvestments/InvestorCall")
);
const PendingPayments = React.lazy(
  () => import("../components/PendingPayments")
);
const Portfolio = React.lazy(() => import("../components/portfolio/Portfolio"));
const PortfolioDetailPage = React.lazy(
  () => import("../components/portfolio/portfolioDetail")
);
const InstalmentPage = React.lazy(() => import("../components/Instalment"));
const CommitmentFlow = React.lazy(() => import("../components/CommitmentFlow"));
const FeesAndReturnCalculator = React.lazy(
  () => import("../components/FeesAndReturnCalculator")
);
import { AuthUser } from "@ifgengineering/client-auth-sdk";
import { authSdk } from "@utils/auth";
import {
  getUserInfoAsync,
  resetAuth,
  signOutAsync,
  updateUserInfo,
} from "../state/auth/auth.actions";
import { navigate } from "gatsby";
import PreFetchData from "@components/PreFetchData";
import { MEDIA_QUERIES, TabProvider } from "@ifgengineering/component-library";
import { useMediaQuery } from "react-responsive";
import { StringParam, useQueryParam } from "use-query-params";
import OnboardingBlocked from "@components/onboarding/OnboardingBlocked";
import DefaultFlow from "@components/Identity/flowPages/default";
import StartVerification from "@components/Identity/flowPages/default/Start";
import StartVerificationExtraDocs from "@components/Identity/flowPages/extra-docs/Start";
import UploadDocsExtraDocs from "@components/Identity/flowPages/extra-docs/Upload";
import EndVerification from "@components/Identity/flowPages/End";
import ExtraDocumentsFlow from "@components/Identity/flowPages/extra-docs";
import { isAuthenticatedSelector } from "@state/auth/auth.selectors";
import useIntercom from "../hooks/useIntercom";
import BlockMobile from "@components/BlockMobile";
import { isNewCommitmentFlowEnabledSelector } from "@state/remoteConfiguration/remoteConfiguration.selectors";

const { refreshAccessToken, userInfo } = authSdk;

export type PublicRouteProps = {
  component?: React.FC<{ path?: string }>;
  [x: string]: unknown;
};

const App: FC = () => {
  useIntercom();

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const newCommitmentFlowEnabled = useSelector(
    isNewCommitmentFlowEnabledSelector
  );
  const dispatch = useDispatch();
  const signOutUser = () => dispatch(signOutAsync());
  const resetStore = () => dispatch(resetAuth());
  const fetchUserInfo = () => dispatch(getUserInfoAsync());
  const location = useLocation();
  const dontLogoutWhenPageIs = [
    "/app/login",
    "/app/signup",
    "/app/signup-apple",
    "/app/verify-email",
    "/app/forgot-password",
    "/app/reset-password",
  ];

  const userInfoSuccessCallback = (user: AuthUser) => {
    dispatch(updateUserInfo(user));
  };

  const failureCallback = async () => {
    if (!dontLogoutWhenPageIs.includes(location.pathname)) {
      await signOutUser();
      await resetStore();
    }
  };

  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const [tabQueryParam] = useQueryParam("tab", StringParam);

  const onTabChangeHandler = (tabId: string) => {
    const url = new URL(location.href);
    url.searchParams.set("tab", tabId);
    history.pushState(null, "", url);
  };

  if (isMobile) {
    return <BlockMobile />;
  }
  const intendedVisitLink = localStorage.getItem("intended_visit_link");
  return (
    <SecurityLayer
      isAuthenticated={isAuthenticated}
      location={!!location?.pathname}
      validateOnLocationChange={true}
      // @ts-expect-error refreshAccessToken is not typed correctly
      refreshAccessToken={refreshAccessToken}
      refreshATSuccessCallback={() => {
        fetchUserInfo();
      }}
      userInfo={userInfo}
      failureCallback={() => {
        failureCallback();
      }}
      twoFactorCallback={() => {
        navigate("/mfa");
      }}
      userInfoSuccessCallback={userInfoSuccessCallback}
      validateInBackground
    >
      <TabProvider
        theme={{
          backgroundColor: "WHITE",
          highlightColor: "BLUE600",
          textColor: "SLATE700",
        }}
        mobile={isMobile}
        selectedTabID={tabQueryParam || ""}
        onTabChange={onTabChangeHandler}
      >
        <PreFetchData>
          <Layout>
            <SEO title="App" />
            <Router basepath="/app" style={{ height: "100%" }}>
              <Redirect
                noThrow
                from="/"
                to={intendedVisitLink || "/app/deals"}
              />
              <LoginContainer path="/login" />
              <SignupForm path="/signup" />
              <SignupApple path="/signup-apple" />
              <VerifyEmail path="/verify-email" />
              <ForgotPassword path="/forgot-password" />
              <ResetPassword path="/reset-password" />

              <PrivateRoute path="/account" component={UserProfile} />

              <PrivateRoute path="/mfa" />

              <PrivateRoute path="/portfolio" component={Portfolio} />

              <PrivateRoute
                path="/portfolio/investment/:investmentId"
                component={PortfolioDetailPage}
              />

              <PrivateRoute path="/identity" component={Identity} />
              <PrivateRoute path="/identity/default" component={DefaultFlow} />
              <PrivateRoute
                path="/identity/default/start"
                component={StartVerification}
              />
              <PrivateRoute
                path="/identity/default/end"
                component={EndVerification}
              />
              <PrivateRoute
                path="/identity/extra-documents-required"
                component={ExtraDocumentsFlow}
              />
              <PrivateRoute
                path="/identity/extra-documents-required/start"
                component={StartVerificationExtraDocs}
              />
              <PrivateRoute
                path="/identity/extra-documents-required/upload"
                component={UploadDocsExtraDocs}
              />
              <PrivateRoute
                path="/identity/extra-documents-required/end"
                component={EndVerification}
              />

              <PrivateRoute path="/deals" component={DealsPage} />

              <PrivateRoute
                path="/deals/:dealId"
                component={MultipleInvestmentsDealPage}
              />
              <PrivateRoute
                path="/deals/:dealId/commitment"
                component={
                  newCommitmentFlowEnabled ? CommitmentFlow : InstalmentPage
                }
              />
              <PrivateRoute
                path="/deals/:dealId/fees-and-return-calculator"
                component={FeesAndReturnCalculator}
              />

              <PrivateRoute
                path="/commitments/:commitmentId"
                component={
                  newCommitmentFlowEnabled ? CommitmentFlow : InstalmentPage
                }
              />

              <PrivateRoute path="/onboarding" component={Onboarding} />
              <PrivateRoute
                path="/onboarding/blocked"
                component={OnboardingBlocked}
              />

              <PrivateRoute path="/bank-details" component={BankDetailsPage} />

              <PrivateRoute
                path="/pending-payments"
                component={PendingPayments}
              />

              <PrivateRoute path="/webinar" component={Webinar} />

              <PrivateRoute
                path="/deals/:dealId/investor-call"
                component={InvestorCall}
              />

              <NotFound path="/*" />
            </Router>
          </Layout>
        </PreFetchData>
      </TabProvider>
    </SecurityLayer>
  );
};

export default App;
